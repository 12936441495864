import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { TranslateService } from '@ngx-translate/core';
import { SaveFormDataService } from 'src/app/shared/services/save-form-data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateService } from 'src/app/shared/services/create.service';
//import { CreateService } from 'src/app/shared/services/create.service';


@Component({
  selector: 'app-supplier-registration',
  templateUrl: './supplier-registration.component.html',
  styleUrls: ['./supplier-registration.component.css']
})
export class SupplierRegistrationComponent implements OnInit {
  radioValue="A";
  validateForm: FormGroup;
  submitted=false;
  already_exist_status = 422;
  success_status = 200;
  allData;

  supplier_registration_form
  public customPatterns = { 'S': { pattern: new RegExp('\[a-zA-Z0-9_*!@#$%&-/, \]') } };

  constructor(private formBuilder: FormBuilder,public saveFormData: SaveFormDataService,
    private router: Router, private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private api: CreateService,private toastr: ToastrService) {
    this.supplier_registration_form=this.formBuilder.group({
      company_name: [null, [Validators.required]],
      address_line_1: (null),
      address_line_2: [null],
      zipcode: [null],
      city: [null],
      country: [null],
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      email_adrress: [null, [Validators.required, Validators.email, Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}')]],
      mobile_number: [null, [Validators.pattern(/^([0|\+[0-9]{1,5})?([0-9]{10})$/), Validators.maxLength(15)]],
      about_jactio: (null),
      other_about_jactio: (null),
      state: [null],
      contract_manufacturing:(false),
      trading:(false),
      engineering:(false),
      fabrication_own_products:(false),
      structrual_steel_work:(false),
      machining:(false),
      sheet_metal_work:(false),
      surface_treatment:(false),
      component_assembly:(false),
      other_services:(false),
      supplier_password: ["", [Validators.required,Validators.pattern('^[a-z]+[0-9]+$'), Validators.minLength(6)]],
    //  checkPassword: [null, [Validators.required, this.matchPassword]],

    })
  
    
   }
  createProfile(){
    if(this.supplier_registration_form.value['contract_manufacturing']==true){
      this.supplier_registration_form.value['contract_manufacturing']="1"
    }
    else{
      this.supplier_registration_form.value['contract_manufacturing']="0"
    }
    if(this.supplier_registration_form.value['trading']==true){
      this.supplier_registration_form.value['trading']="1"
    }
    else{
      this.supplier_registration_form.value['trading']="0"
    }
    if(this.supplier_registration_form.value['engineering']==true){
      this.supplier_registration_form.value['engineering']="1"
    }
    else{
      this.supplier_registration_form.value['engineering']="0"
    }
    if(this.supplier_registration_form.value['fabrication_own_products']==true){
      this.supplier_registration_form.value['fabrication_own_products']="1"
    }
    else{
      this.supplier_registration_form.value['fabrication_own_products']="0"
    }
    if(this.supplier_registration_form.value['structrual_steel_work']==true){
      this.supplier_registration_form.value['structrual_steel_work']="1"
    }
    else{
      this.supplier_registration_form.value['structrual_steel_work']="0"
    }
    if(this.supplier_registration_form.value['machining']==true){
      this.supplier_registration_form.value['machining']="1"
    }
    else{
      this.supplier_registration_form.value['machining']="0"
    }
    if(this.supplier_registration_form.value['sheet_metal_work']==true){
      this.supplier_registration_form.value['sheet_metal_work']="1"
    }
    else{
      this.supplier_registration_form.value['sheet_metal_work']="0"
    }
    if(this.supplier_registration_form.value['surface_treatment']==true){
      this.supplier_registration_form.value['surface_treatment']="1"
    }
    else{
      this.supplier_registration_form.value['surface_treatment']="0"
    }
    if(this.supplier_registration_form.value['component_assembly']==true){
      this.supplier_registration_form.value['component_assembly']="1"
    }
    else{
      this.supplier_registration_form.value['component_assembly']="0"
    }
    if(this.supplier_registration_form.value['other_services']==true){
      this.supplier_registration_form.value['other_services']="1"
    }
    else{
      this.supplier_registration_form.value['other_services']="0"
    }
  
    this.supplier_registration_form.value["tab_name"] = "supplier_registration_form";
    console.log(this.supplier_registration_form['value'])

    this.saveData(this.supplier_registration_form['value']);
    this.submitForm();
   
  }
  saveData(formData){
    this.saveFormData.setFormData('supplier_registration_form',formData);
  }
  submitForm(){
    
   this.allData=this.saveFormData.getFormData('supplier_registration_form')
    console.log(this.saveFormData.getAllFormData(),'All forms save data')
    var details = JSON.stringify(this.allData);

    var formData1 = new FormData();
    formData1.append('json_data', details)

    this.spinner.show();

    const FormResponse=this.api.supplierProfile(formData1);
    FormResponse.subscribe(
      response => {
       this.success(response, "supplier_registration_form");
       console.log("sucess");
       this.spinner.hide();
       this.saveFormData.setFormData('supplier_registration_form', {})
       this.saveFormData.clearReadingFormData();
       this.resetForm();
      },
      error => {
        console.error("errro", error);
      });
  }
  resetForm() {
    this.submitted = false;
    this.supplier_registration_form.reset();
    this.saveFormData.clearReadingFormData();
  }
  updateConfirmValidator(): void {
    Promise.resolve().then(() => this.validateForm.controls.checkPassword.updateValueAndValidity());
  }

success(response, api_type) {
  const vim = this;
  if (api_type == "supplier_registration_form") {
    if (vim.isSuccess(response)) {
      vim.toastr.success("", "Supplier Profile Created Successful");
      vim.router.navigate(["/supplier"]);
    } else {
      if (vim.isAlreadyExist(response)) {
        vim.toastr.warning("Already Exist!!", response["message"]);
      } else {
        vim.errorToasty(response);
      }
    }
  }
}

/**
 *
 * @param error
 * @param api_type
 * @purpose :-  This is error handler method is called.
 * @method: errorHandler
 */
errorHandler(error, api_type) {
  const vim = this;
  if (api_type == "supplierProfile") {
    vim.errorToasty(error);
  }
}

/**
 *
 * @param response
 * @method: it is a common herlper for check the status is 200 or not
 */
isSuccess(response) {
  const vim = this;
  if (
    response.hasOwnProperty("status") &&
    response["status"] === vim.success_status
  ) {
    return true;
  }
  return false;
}

isAlreadyExist(response) {
  const vim = this;
  if (
    response.hasOwnProperty("status") &&
    response["status"] === vim.already_exist_status
  ) {
    return true;
  }
  return false;
}
errorToasty(error) {
  const vim = this;
  if (error.hasOwnProperty("message")) {
    vim.toastr.error("Error!", error["message"]);
  } else {
    vim.toastr.error("Error!", "Somethink wrong!!!..");
  }
}

  ngOnInit(){
    
  }

}
