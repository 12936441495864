import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateLevel'
})
export class DateLevelPipe implements PipeTransform {

  transform(date): any {
    const date1 = new Date(date);
    const level_date = date1.getFullYear() + '-' + (date1.getMonth() + 1) + '-' + date1.getDate();
    return level_date;
  }

}
