import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-structural-steel',
  templateUrl: './structural-steel.component.html',
  styleUrls: ['./structural-steel.component.css']
})
export class StructuralSteelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
