import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'
import { NzI18nService, de_DE, en_US } from 'ng-zorro-antd';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
  isCollapsed = false;
  siderWidth=230
  selectedLang="German"
  isEnglish = false;
  showMenu=true;

  href
  constructor(public translate:TranslateService,private i18n: NzI18nService,private route: ActivatedRoute,private router: Router) { 
    translate.addLangs(['German','English'])
    translate.setDefaultLang('German')
    
    
    
  }

  
  switchLang(lang){
    console.log(lang)
    const browserLang=this.translate.getBrowserLang();
    console.log(browserLang)
    if(lang == "English")
    {
     this.isEnglish= true;
    }else{
     this.isEnglish= false;
    }
     this.translate.use(lang);
     this.i18n.setLocale(this.isEnglish ? en_US:de_DE);
  }
  

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let urlLang = params['language'];
      console.log(urlLang);
      this.switchLang(urlLang)
    });
   
  }
 
}

