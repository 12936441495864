import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-surface-treatment',
  templateUrl: './surface-treatment.component.html',
  styleUrls: ['./surface-treatment.component.css']
})
export class SurfaceTreatmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
