import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SupplierRegistrationComponent } from './dashboard/supplier-registration/supplier-registration.component';


const routes: Routes = [
   {
      path: 'other',
      loadChildren:'./dashboard/inquiry-form/other/other.modules#OtherModule'
    },
    {
      path: 'component_assembly',
      loadChildren:'./dashboard/inquiry-form/component-assembly/assembly.modules#AssemblyModule'
    },
    {
      path: 'machining_milling',
      loadChildren:'./dashboard/inquiry-form/machining-milling/milling.modules#MillingModule'
    },
    {
      path: 'machining_other',
      loadChildren:'./dashboard/inquiry-form/machining-other/otherMachining.modules#OtherMachiningModule'
    },
    {
      path: 'machining_turning',
      loadChildren:'./dashboard/inquiry-form/machining-turning/turning.modules#TurningModule'
    },
    {
      path: 'steel_metal_work',
      loadChildren:'./dashboard/inquiry-form/steel-metal-work/sheet.modules#SheetModule'
    },
    {
      path: 'structural_steel',
      loadChildren:'./dashboard/inquiry-form/structural-steel/structural.modules#StructuralModule'
    },
    {
      path: 'surface_treatment',
      loadChildren:'./dashboard/inquiry-form/surface-treatment/surface.modules#SurfaceModule'
    },
    {
      path: 'welded_parts',
      loadChildren:'./dashboard/inquiry-form/welded-parts/welded.modules#WeldedModule'
    },

  { path: '', redirectTo: '/structural_steel/project_requirements', pathMatch: 'full' },
  {path: 'supplier', component: SupplierRegistrationComponent},
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true,scrollPositionRestoration:'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
