import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appFormDirective]'
})
export class FormDirectiveDirective {
 
  constructor(private el: ElementRef) { }
  @HostListener('window:click', ['$event.target'])
  onClick() {
    const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');
    if (invalidControl) {
      invalidControl.focus();
    }
}
}
