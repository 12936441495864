import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CreateService {
  status = false;
  baseUrl = environment.server_url;
  constructor(private http:HttpClient) { }
  
  supplierProfile(data) {
    return this.http.post(this.baseUrl + 'create_supplier.php', data);
  }
  submitSteelMetalwork(data){
    return this.http.post(this.baseUrl + 'lead_sheet_metal.php', data);
  }
  submitSurfaceTreatmentForm(data){
    return this.http.post(this.baseUrl + 'lead_surface_treatment.php',data);
  }
  submitMillingForm(data){
    return this.http.post(this.baseUrl + 'lead_machining_milling.php',data);
  }
  submitTurningForm(data){
    return this.http.post(this.baseUrl + 'lead_machining_turning.php',data);
  }
  submitMachiningOtherForm(data){
    return this.http.post(this.baseUrl + 'lead_machining_other.php',data);
  }
  submitComponentAssemblyForm(data){
    return this.http.post(this.baseUrl + 'lead_component_assembly.php', data);

  }
  submitOtherForm(data){
    return this.http.post(this.baseUrl + 'lead_other.php', data);

  }
  submitStructuralForm(data){
    return this.http.post(this.baseUrl + 'lead_structural_steel.php', data);
     
  }
  submitWeldedPartsForm(data){
    return this.http.post(this.baseUrl + 'lead_welded_parts.php', data);
  }
  InquiryConfirmationMail(data){
    return this.http.post('https://devcrm.jactio.com/index.php?entryPoint=send_inquiry_confirmation_mail', data);
  }
  fileUpload(data){
   // console.log(data)
    return this.http.post('https://devcrm.jactio.com/crm_rest/FileUpload.php',data)
  }
  uploadFile(url: string, file: File): Observable<HttpEvent<any>> {

    let formData = new FormData();
    formData.append('upload', file);

    let params = new HttpParams();

    const options = {
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest('POST', url, formData, options);
    return this.http.request(req);
  }
  jwt(token = null) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data'
      })
    };
    return httpOptions;
  }
}
