import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-machining-turning',
  templateUrl: './machining-turning.component.html',
  styleUrls: ['./machining-turning.component.css']
})
export class MachiningTurningComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
