import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-steel-metal-work',
  templateUrl: './steel-metal-work.component.html',
  styleUrls: ['./steel-metal-work.component.css']
})
export class SteelMetalWorkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
