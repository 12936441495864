import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import{ TranslateModule,TranslateLoader } from '@ngx-translate/core';
import{ TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule,HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import de from '@angular/common/locales/de';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';
import { HomeComponent } from './dashboard/home/home.component';
import { SupplierRegistrationComponent } from './dashboard/supplier-registration/supplier-registration.component';
import { ToastrModule } from 'ngx-toastr';
import { CompanyDataCommonComponent } from './common/company-data-common/company-data-common.component';
import { SteelMetalWorkComponent } from './dashboard/inquiry-form/steel-metal-work/steel-metal-work.component';
import { SurfacePrepareComponent } from './common/surface-prepare/surface-prepare.component';
import { ProjectDesComponent } from './common/project-des/project-des.component';
import { DateLevelPipe } from './shared/pipes/date-level.pipe';
import { SurfaceTreatmentComponent } from './dashboard/inquiry-form/surface-treatment/surface-treatment.component';
import { ComponentAssemblyComponent } from './dashboard/inquiry-form/component-assembly/component-assembly.component';
import { OtherComponent } from './dashboard/inquiry-form/other/other.component';
import { StructuralSteelComponent } from './dashboard/inquiry-form/structural-steel/structural-steel.component';
import { WeldedPartsComponent } from './dashboard/inquiry-form/welded-parts/welded-parts.component';
import { MachiningMillingComponent } from './dashboard/inquiry-form/machining-milling/machining-milling.component';
import { MachiningTurningComponent } from './dashboard/inquiry-form/machining-turning/machining-turning.component';
import { MachiningOtherComponent } from './dashboard/inquiry-form/machining-other/machining-other.component';
import { FormDirectiveDirective } from './shared/directive/form-directive.directive';
import { NgxSpinnerModule } from "ngx-spinner";
import { SliderComponent } from './slider/slider.component';

registerLocaleData(en);
registerLocaleData(de);


const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

// export function HttpLoaderFactory(http:HttpClient){
//   return new TranslateHttpLoader(http)
// }

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SupplierRegistrationComponent,
    CompanyDataCommonComponent,
    SteelMetalWorkComponent,
    SurfacePrepareComponent,
    // ProjectRequirementsSteelComponent,
    // ProjectDescriptionSteelComponent,
    ProjectDesComponent,
    DateLevelPipe,
    // CompanyDataSteelComponent,
    SurfaceTreatmentComponent,
    // ProjectRequirementSurfaceComponent,
    // ProjectDescriptionSurfaceComponent,
    // CompanyDataSurfaceComponent,
    ComponentAssemblyComponent,
    // CompanyDataComponentComponent,
    // ProjectRequirementComponentComponent,
    // ProjectDescriptionComponentComponent,
    OtherComponent,
   // ProjectDescriptionOtherComponent,
   // ProjectRequirementOtherComponent,
    //CompanyDataOtherComponent,
    StructuralSteelComponent,
    // ProjectRequirementStructuralComponent,
    // CompanyDataStructuralComponent,
    // ProjectDescriptionStructuralComponent,
    WeldedPartsComponent,
    // PrWeldedComponent,
    // PdWeldedComponent,
    // CdWeldedComponent,
    MachiningMillingComponent,
    // PrMillingComponent,
    // PdMillingComponent,
    // CdMillingComponent,
    MachiningTurningComponent,
    // PrTurningComponent,
    // PdTurningComponent,
    // CdTurningComponent,
    MachiningOtherComponent,
    // PrOtherComponent,
    // PdOtherComponent,
    // CdOtherComponent,
    FormDirectiveDirective,
    SliderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IconsProviderModule,
    NgZorroAntdModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader:{
        provide:TranslateLoader,
        useFactory:HttpLoaderFactory,
        deps:[HttpClient]
      }
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
  ],
  providers: [{ provide: NZ_I18N, useValue: en_US }],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http:HttpClient){
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json")
}
