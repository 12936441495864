import { Component,forwardRef, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { SaveFormDataService } from 'src/app/shared/services/save-form-data.service';
import { Router } from '@angular/router';
import { CreateService } from 'src/app/shared/services/create.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-company-data-common',
  templateUrl: './company-data-common.component.html',
  styleUrls: ['./company-data-common.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanyDataCommonComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CompanyDataCommonComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class CompanyDataCommonComponent implements OnInit,OnDestroy {
  subscriptions: Subscription[] = [];
  
  current=2;
  allData
  Company_data:FormGroup;
  submitted:Boolean;
  constructor(private fb: FormBuilder,
    public saveFormData:SaveFormDataService,
    private router: Router,
    private api: CreateService) {
      this.Company_data = this.fb.group({
        company_name: [null, [Validators.required]],
        address_line_1: [null, [Validators.required]],
        address_line_2: [null],
        zipcode: [null, [Validators.required]],
        city: [null, [Validators.required]],
        country: [null, [Validators.required]],
        name_prefix: [null, [Validators.required]],
        first_name: [null, [Validators.required]],
        last_name: [null, [Validators.required]],
        email_adrress: [null, [Validators.required]],
        phone_number: [null],
        mobile_number: [null, [Validators.required,Validators. minLength(10)]],
        about_jactio:(null),
        other_about_jactio:(null),
        agreement:(null)
      });

      this.subscriptions.push(
        this.Company_data.valueChanges.subscribe(value => {
          this.onChange(value);
          this.onTouched();
        })
      );
     }

  ngOnInit() {
   
  }
  get value(): CompanyDataCommonComponent {
    return this.Company_data.value;
    
  }
  set value(value: CompanyDataCommonComponent) {
    this.Company_data.setValue(value);
    this.onChange(value);
    this.onTouched();
   
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.Company_data.reset();
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }
  
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  validate() {
    return this.Company_data.valid ? null : { Company_data: { valid: false, }, };
  }
  
 

}
