import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SaveFormDataService {
  public formData={};
public allFormValidationStatus={};
//public reading:string;
//public babyReadingData={};
public checkComponentState = {
  //'mother-profile':false,
  'welded-details':false,
  'welded-dimension': false,
  'welded-surface': false,
  'welded-material': false,
  'welded-quantites': false,
  'welded-project-data': false,
  'welded-project-description': false,
  'welded-supplier-criteria':false,
  'welded-company':false,

};
private messageSource = new BehaviorSubject('default message');
tabMessage = this.messageSource.asObservable();

setFormData(formName,data){
  this.formData[formName]=data;
}
setComponentFlag(componentName) {
  this.checkComponentState[componentName] = true
}

getComponentFlag(){
  return this.checkComponentState;
}
setActiveTab(message: string) {
  this.messageSource.next(message)
}
getFormData(formName){
  return this.formData[formName];
}
getAllFormData(){
  return this.formData;
}
clearReadingFormData(){
  this.formData={};
}


  constructor() { }
}
