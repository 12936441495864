import { Component, OnInit, forwardRef, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TouchSequence } from 'selenium-webdriver';

export interface QualityCertificate {
  id: number;
  name: string;
}


@Component({
  selector: 'app-project-des',
  templateUrl: './project-des.component.html',
  styleUrls: ['./project-des.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProjectDesComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ProjectDesComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectDesComponent implements OnInit {
  current=1;
  project_description:FormGroup;

  subscriptions: Subscription[] = [];
  QualityCertificateList: QualityCertificate[] = [
    { id: 0, name: 'ISO 9001' },
    { id: 1, name: 'VDA 6.1/6.4 ' },
    { id: 2, name: 'ISO 14001' },
    { id: 3, name: 'BS OSHAS 18001 ' },
    { id: 4, name: 'ISO 50001  ' },
    { id: 5, name: 'EN 9100 ' },
    { id: 6, name: 'TS 16949 '},
    { id: 7, name: 'ISO 13485  '},
    { id: 8, name: 'ISO 13445  '},
    { id: 9, name: 'other  '},


  ];
  certificate:any;
  marked = false;
  theCheckbox = false;
  selectedFile :File=null;

  constructor(private fb: FormBuilder,) { 
    this.project_description = this.fb.group({
      place_of_delivery: (null),
      project_due_date: (null),
      date_of_delivery: [null],
      delivery_terms: (null),
      production_type: (null),
      min_turnover: (null),
      min_employees: (null),
      language_skill: (null),
     // quality_certificate: this.fb.array([]),
      other_quality_certificate: (null),
      project_des:[null, [Validators.required]],
      project_files: this.fb.array([]),
      nda_required:(null),
      //quality certificate
      iso_9001:(false),
      vda_6_1_6_4:(false),
      iso_14001:(false),
      bs_oshas_18001:(false),
      iso_50001:(false),
      en_9100:(false),
      ts_16949:(false),
      iso_13485:(false),
      iso_13445:(false),
      other_quality_list:(false),


    });

    this.subscriptions.push(
      this.project_description.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
   
  }
  onFileSelected(event){
    console.log(event.file);
    this.selectedFile=event.file;
  //  console.log(this.selectedFile)
    // this.project_description.value['project_files']=this.selectedFile;
    this.certificate = (this.project_description.controls.project_files as FormArray);
             this.certificate.push(new FormControl(event.file));
   }
 
  ngOnInit() {
  }
  toggleVisibility(e){
    this.marked= e.target.checked;
    console.log(this.marked)
    if(this.marked==true){
      this.project_description.value['nda_required']="Yes"
    }
    else{
      this.project_description.value['nda_required']="No"
    }
  }
  get value(): ProjectDesComponent {
    return this.project_description.value;
    
  }
  set value(value: ProjectDesComponent) {
    this.project_description.setValue(value);
    this.onChange(value);
    this.onTouched();
   
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.project_description.reset();
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }
  
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  validate() {
    return this.project_description.valid ? null : { project_description: { valid: false, }, };
  }
  change(type:string,name: string, isChecked: boolean) {
    if(type=="quality_certificate"){
          this.certificate = (this.project_description.controls.quality_certificate as FormArray);
          if (isChecked) {
             this.certificate.push(new FormControl(name));
          } else {
             const index = this.certificate.controls.findIndex(x => x.value === name);
             this.certificate.removeAt(index);
          }
    }
  }

  
}
