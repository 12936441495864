import { Component, OnInit, forwardRef, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

export interface SurfacePreparation {
  id: number;
  name: string;
}
@Component({
  selector: 'app-surface-prepare',
  templateUrl: './surface-prepare.component.html',
  styleUrls: ['./surface-prepare.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SurfacePrepareComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SurfacePrepareComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurfacePrepareComponent implements OnInit {
  surfacePreparation:FormGroup;
  subscriptions: Subscription[] = [];

  surfacePreparationList: SurfacePreparation[] = [
    { id: 0, name: 'Wet Painting' },
    { id: 1, name: 'KTL Coating' },
    { id: 2, name: 'Electrogalvanizing' },
    { id: 3, name: 'Glass Bead Blasting' },
    { id: 4, name: 'Shot Peening' },
    { id: 5, name: 'Polishing' },
    { id: 6, name: 'Anodizing' },
    { id: 7, name: 'Chrome Plating' },
    { id: 8, name: 'Browning' },
    { id: 9, name: 'Powder Coating' },
    { id: 10, name: 'Hot Dip Galvanizing' },
    { id: 11, name: 'Sand Blasting' },
    { id: 12, name: 'Ice Blasting' },
    { id: 13, name: 'Glass Bead Blasting' },
    { id: 14, name: 'PVD Coating' },
    { id: 15, name: 'Electropolishing' },
    { id: 16, name: 'Nickel Plating' },
    { id: 17, name: 'Engraving' },
    { id: 18, name: 'Nitriding' },
    { id: 19, name: 'Other' },

  ];
  surfacePrep: any;
  constructor(private fb: FormBuilder) { 
    this.surfacePreparation = this.fb.group({
      s_preparation: this.fb.array([]),
    });
    this.subscriptions.push(
      this.surfacePreparation.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
    
  }

  ngOnInit() {
  }
  get value(): SurfacePrepareComponent {
    return this.surfacePreparation.value;
    
  }
  set value(value: SurfacePrepareComponent) {
    this.surfacePreparation.setValue(value);
    this.onChange(value);
    this.onTouched();
   
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.surfacePreparation.reset();
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }
  
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  validate() {
    return this.surfacePreparation.valid ? null : { surfacePreparation: { valid: false, }, };
  }
  surface_prep(name: string, isChecked: boolean) {
    console.log("sur......!!!")
   this.surfacePrep = (this.surfacePreparation.controls.s_preparation as FormArray);
   if (isChecked) {
     this.surfacePrep.push(new FormControl(name));
 
  } else {
    const index = this.surfacePrep.controls.findIndex(x => x.value === name);
    this.surfacePrep.removeAt(index);
  }
 }
 

}
